import React from 'react';
import Img from 'gatsby-image';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import { breakpoints } from '../styles/Variables.js';
import ContactForm from './ContactForm/ContactForm.js';
import Video from './Video/Video.js';
import { H1BoxBlack } from '../styles/common/Element';

const { bpMedium, bpLarge, bpXlarge } = breakpoints;

const SectionStyles = styled.section`
  background-color: var(--white);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 3rem 0;
`;

const ContentStyles = styled.div`
  color: var(--black);
  text-align: center;
  padding: 5px;
  padding: clamp(5px, 1vw, 5px);
  margin-bottom: 3rem;
  margin-top: 1rem;
  margin: 0 4%;

  @media (min-width: ${bpMedium}) {
    margin: 0 8%;
  }

  @media (min-width: ${bpLarge}) {
    margin: 0 12%;
  }

  @media (min-width: ${bpXlarge}) {
    margin: 0 18%;
  }

  p {
    text-align: center;
  }
`;

const GridStyles = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  p {
    text-align: left;
    color: var(--black);
  }
`;

export default function SectionContact() {
  return (
    <>
      <SectionStyles id="anmeldung">
        <ContentStyles>
          <H1BoxBlack>KONTAKT</H1BoxBlack>
          <GridStyles>
            <div className="item">
            <Video
              videoSrcURL="https://www.youtube.com/embed/zibJo0P7JL0"
              videoTitle="KulturgesichterNRW - Coming Soon"
            />
            </div>
            <div className="item">
              <p>
                Wenn du generelle Fragen rund um die Aktion Kulturgesichter
                hast oder du mit uns aus anderen Gründen in Kontakt treten
                willst, nutze bitte dieses Kontaktformular. <br />
                <br />
                Wir melden uns schnellstmöglich bei Euch.
              </p>
              <ContactForm form="contact" />
            </div>
          </GridStyles>
        </ContentStyles>
      </SectionStyles>
    </>
  );
}
