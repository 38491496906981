import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SectionInfo from '../components/SectionInfo';
import SectionGesichter from '../components/SectionGesichter';
import SectionContact from '../components/SectionContact';
import SEO from '../components/SEO';
import SlideShow from '../components/SlideShow';
import SectionPartnerCity from '../components/SectionPartnerCity';
import SectionDonation from '../components/SectionDonation';
import Layout from '../components/Layout';
import { Section } from '../styles/common/Layout';

export default function HomePage({ data }) {
  const { settings } = data;
  return (
    <Layout bgColor={'black'}>
      <SEO
        title="Die Kulturgesichter in Deutschland"
        keywords={[`Kulturgesichter`, `ORG`, `Alarmstufe Rot`, `Alarmstufe`]}
        image={data.settings.image.asset.fixed}
      />
      <Section id="index">
        <SectionInfo />
        <SectionContact />
        <SectionPartnerCity />
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query SettingsQuery {
    settings: sanityStoreSettings {
      name
      image {
        asset {
          fixed(width: 325) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
  }
`;
