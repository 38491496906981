import React from 'react';
import { useStaticQuery, StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const InfoGrid = styled.section`
  display: grid;
  grid-gap: 0rem;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  padding-bottom: 12rem;
  overflow: hidden;
`;

const PersonStyles = styled.div`
  position: relative;
  a {
    text-decoration: none;
  }
  .gatsby-image-wrapper {
    height: 550px;
  }
  h2 {
    font-family: 'ImpactLabelReversed', sans-serif;
    transform: rotate(-2deg);
    text-align: center;
    font-size: 4rem;
    margin-bottom: -2rem;
    position: relative;
    z-index: 2;
  }
  &:hover .overlay {
    opacity: 0.75;
  }
`;

const OverlayStyles = styled.section`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: var(--black);

  .years {
    font-family: 'ImpactLabelReversed', sans-serif;
    font-size: 1.8rem;
    line-height: 1;
    background: var(--white);
    padding: 1rem;
    margin: 2rem;
    margin-top: -6rem;
    z-index: 2;
    position: relative;
    transform: rotate(-2deg);
    text-align: center;
    color: var(--black);
  }

  .description {
    font-family: 'ImpactLabelReversed', sans-serif;
    background: var(--white);
    padding: 1rem;
    margin: 2rem;
    margin-top: 0rem;
    z-index: 2;
    position: relative;
    transform: rotate(-2deg);
    text-align: center;
    color: var(--black);
  }
`;

const TextStyles = styled.div`
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
`;

export default function SectionGesichter() {
  const data = useStaticQuery(graphql`
    query GesichterQuery {
      gesichter: allSanityPerson {
        nodes {
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          id
          name
          slug {
            current
          }
          description
          job
          years
        }
      }
    }
  `);
  const persons = data.gesichter.nodes;
  return (
    <>
      <InfoGrid id="kulturgesichter">
        {persons.map((person) => (
          <PersonStyles key={person.id}>
            <Img fluid={person.image.asset.fluid} />
            <OverlayStyles className="overlay">
              <h2>
                <span className="mark">{person.name}</span>
              </h2>

              <TextStyles className="text">
                <p className="years">
                  Seit {person.years} Jahren in der Branche
                </p>
                <p className="description">{person.description}</p>
              </TextStyles>
            </OverlayStyles>
          </PersonStyles>
        ))}
      </InfoGrid>
    </>
  );
}
