import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';

const FormStyles = styled(Form)`
    background: var(--white);
    margin: 15px 0;
    text-align: left;
}
`;

const Fieldset = styled.fieldset`
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
`;

const Input = styled(Field)`
  font-size: 1.6rem;
  width: 100%;
  border: ${(props) => props.border || '1px solid #ccc'};
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.2s;
  touch-action: manipulation;

`;

const LabelStyles = styled.label`
  transition: all 0.2s;
  touch-action: manipulation;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  `

const FieldsetStyles = styled.div`

  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;

`

const TextareaStyles = styled.input`
  height: 100px;
  max-width: 100%;
  resize: none;
`;

const ButtonSubmit = styled.button.attrs({
  className: 'btn btn-lg btn-black ',
})`
  cursor: pointer;
  text-align: left;
`;

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const ContactForm = ({ form }) => (
  <Formik
    initialValues={{
      name: '',
      email: '',
      message: '',
    }}
    onSubmit={(values, actions) => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': form, ...values }),
      })
        .then(() => {
          alert('Success');
          actions.resetForm();
        })
        .catch(() => {
          alert('Error');
        })
        .finally(() => actions.setSubmitting(false));
    }}
    validate={(values) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const errors = {};
      if (!values.name) {
        errors.name = 'Name Required';
      }
      if (!values.email || !emailRegex.test(values.email)) {
        errors.email = 'Valid Email Required';
      }
      if (!values.message) {
        errors.message = 'Message Required';
      }
      return errors;
    }}
  >
    {({ touched, errors, values, handleChange, handleBlur, handleSubmit }) => (
      <FormStyles name={form} data-netlify>
        <FieldsetStyles>
          <Input
            border={errors.name && '1px solid red'}
            placeholder="Dein Name"
            name="name"
          />
          <LabelStyles htmlFor ="message">Dein Name</LabelStyles>
        </FieldsetStyles>
        <FieldsetStyles>

          <Input
            border={touched.email && errors.email && '1px solid red'}
            placeholder="Deine E-Mail"
            name="email"
          />
          <LabelStyles htmlFor ="email">Deine E-Mail</LabelStyles>
        </FieldsetStyles>
        <FieldsetStyles>
          <Input
            border={touched.message && errors.message && '1px solid red'}
            placeholder="Deine Nachricht"
            name="message"
            component="textarea"
          />
          <LabelStyles htmlFor ="message">Deine Nachricht</LabelStyles>
        </FieldsetStyles>
        <Fieldset>
    <ButtonSubmit type="submit">{form === 'shooting' ? 'Zum Shooting anmelden' : 'Nachricht Absenden'}</ButtonSubmit>
        </Fieldset>
      </FormStyles>
    )}
  </Formik>
);

export default ContactForm;
